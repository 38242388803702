
$(function () {
	$(".accodion .accodion__ttl").on("click", function () {
		if ($(this).hasClass('is-active')) {
			$(this).next().removeClass('is-open');
			$(this).next().addClass('is-close');
			$(this).removeClass('is-active');
		} else {
			$(this).next().removeClass('is-close');
			$(this).next().addClass('is-open');
			$(this).addClass('is-active');
		}
	});
});

$(function () {
	$(".sp__accordion >.header__spmenu").on("click", function () {
		if ($(this).hasClass('is-active')) {
			$(this).next().removeClass('is-open');
			$(this).next().addClass('is-close');
			$(this).removeClass('is-active');
		} else {
			$(this).next().removeClass('is-close');
			$(this).next().addClass('is-open');
			$(this).addClass('is-active');
		}
	});
});

$(function () {
	$(".sp__accordion >p").on("click", function () {
		if ($(this).hasClass('is-active')) {
			$(this).next().removeClass('is-open');
			$(this).next().addClass('is-close');
			$(this).removeClass('is-active');
		} else {
			$(this).next().removeClass('is-close');
			$(this).next().addClass('is-open');
			$(this).addClass('is-active');
		}
	});
});

$(function () {
	$(".sp__accordion2").on("click", function () {
		if ($(this).hasClass('is-active')) {
			$(this).parent().next().removeClass('is-open');
			$(this).parent().next().addClass('is-close');
			$(this).removeClass('is-active');
		} else {
			$(this).parent().next().removeClass('is-close');
			$(this).parent().next().addClass('is-open');
			$(this).addClass('is-active');
		}
	});
});

$(function () {
	$(".sp-d-menu__bg").on("click", function () {
		console.log('bg');
		$('.sp-d-menu').removeClass('is-open');
		$('.sp-d-menu').addClass('is-close');
		$('.header__spmenu').removeClass('is-active');
	});
});