// gnab
jQuery(function() {
    $('.header__menu > .d-menu__base > span').on("click",function(){
        if($('.header__search >.d-menu').hasClass('u-hide')){
        }else{
            $('.header__search >.d-menu').addClass('u-hide');
        }
        if($(this).parents('.d-menu__base').find('.d-menu').hasClass('u-hide')){
            $('.header__wrapper').find(".d-menu").each(function(){
                $(this).addClass('u-hide');
            });
            $('.header__wrapper').find('span').each(function(){
                $(this).removeClass('tri__pic');
            });
            $('.header__search').find('.header__search__block').each(function(){
                $(this).removeClass('tri__pic');
            });    
            $(this).addClass('tri__pic');
            $(this).parents('.d-menu__base').find('.d-menu').removeClass('u-hide');
        }else{
            $(this).parents('.d-menu__base').find('.d-menu').addClass('u-hide');
        }
    })

    $('.header__menu > .d-menu__base > .d-menu >.d-menu__bg').on("click",function(){
        $(this).parents().find('.d-menu').addClass('u-hide');
        $('.header__wrapper').find('span').each(function(){
            $(this).removeClass('tri__pic');
        });
        $('.header__search').find('.header__search__block').each(function(){
            $(this).removeClass('tri__pic');
        });
    })
    $('.header__search >.header__search__block').on("click",function(){
        if($('.header__search >.d-menu').hasClass('u-hide')){
            $('.d-menu__base').find(".d-menu").each(function(){
                $(this).addClass('u-hide');
            });
            $('.header__search >.d-menu').toggleClass('u-hide');
            console.log('up');
        }else{
            $('.header__search >.d-menu').addClass('u-hide');
            console.log('down');
        }
    })
    $('.header__search >.d-menu >.d-menu__bg').on("click",function(){
        $(this).parents().find('.d-menu').addClass('u-hide');
        $('.header__search').find('.header__search__block').each(function(){
            $(this).removeClass('tri__pic');
        });
    })

});

// pagetop
jQuery(function() {
    $('.is-totop').click(function () {
        $('body, html').animate({ scrollTop: 0 }, 500);
        return false;
    });
});

// selectbox
jQuery(function() {
    $(".custom-select").each(function() {
        var classes = $(this).attr("class"),
            id      = $(this).attr("id"),
            name    = $(this).attr("name");
        var template =  '<div class="' + classes + '">';
            template += '<span class="custom-select-trigger">' + $(this).attr("placeholder") + '</span>';
            template += '<div class="custom-options">';
            $(this).find("option").each(function() {
            template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
            });
        template += '</div></div>';
        
        $(this).wrap('<div class="custom-select-wrapper"></div>');
        $(this).hide();
        $(this).after(template);
    });
    $(".custom-option:first-of-type").hover(function() {
        $(this).parents(".custom-options").addClass("option-hover");
    }, function() {
        $(this).parents(".custom-options").removeClass("option-hover");
    });
    $(".custom-select-trigger").on("click", function() {
        $('html').on('click',function() {
        $(".custom-select").removeClass("opened");
        });
        $(this).parents(".custom-select").toggleClass("opened");
        event.stopPropagation();
    });
    $(".custom-option").on("click", function() {
        $(this).parents(".custom-select-wrapper").find("select").val($(this).data("value"));
        $(this).parents(".custom-options").find(".custom-option").removeClass("selection");
        $(this).addClass("selection");
        $(this).parents(".custom-select").removeClass("opened");
        $(this).parents(".custom-select").find(".custom-select-trigger").text($(this).text());
    });
});

// serch enter
jQuery(function() {
    $('#ss-form1 input').keypress(function(e){
        if(e.which == 13) {
            document.charset='UTF-8';
            $(this).parents('form').submit();
            return false;
        }
    });
});
jQuery(function() {
    $('#ss-form2 input').keypress(function(e){
        if(e.which == 13) {
            document.charset='UTF-8';
            $(this).parents('form').submit();
            return false;
        }
    });
});

jQuery(function() {
    objectFitImages();
});

